import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Button, Form, Input } from "antd";
import { message } from "antd";
import axios from "axios";

import { useTelegram } from "../../hooks/useTelegram";
import { CONFIG } from "../../constants/config";

import "./InstallPasswordPage.css";

const InstallPasswordPage = () => {
  const [loading, setLoading] = useState(false);
  const [errText, setErrText] = useState("");

  const [searchParams] = useSearchParams();
  const { tg, onClose } = useTelegram();

  const chatId = searchParams.get("chat_id");
  const apiKey = searchParams.get("api_key");
  tg.MainButton.hide();

  const onFinish = async (values) => {
    setLoading(true);
    try {
      await axios
        .put(`${CONFIG.CLIENT_API_URL}/install/password/?api_key=${apiKey}`, {
          ...values,
          chatId,
        })
        .then(({ data: resData }) => {
          setLoading(false);
          onClose();
        })
        .catch((error) => {
          message.error(error.response?.data?.message)
          console.log(error.response);
          setErrText(error.response?.data?.message);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      console.error("Ошибка:", error);
      message.error("Произошла ошибка при отправке данных!");
    }
    setLoading(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const validateMessages = {
    required: "${label} обязательное поле!",
    types: {
      number: "${label} не является числом!",
    },
    number: {
      range: "${label} должно быть между ${min} и ${max}",
    },
  };

  const validateUsername = (rule, value) => {
    const usernameRegex = /^[a-zA-Z0-9]+$/;
    if (value && !usernameRegex.test(value)) {
      return Promise.reject(
        "Неверный формат username! Допускаются только латинские буквы и цифры."
      );
    } else {
      return Promise.resolve();
    }
  };

  const validatePassword = (rule, value) => {
    if (value && value.length >= 6) {
      // Проверка содержания хотя бы одной заглавной буквы и одной цифры
      const upperCaseRegex = /[A-Z]/;
      const digitRegex = /\d/;
      if (!upperCaseRegex.test(value) || !digitRegex.test(value)) {
        return Promise.reject(
          "Пароль должен содержать хотя бы одну заглавную букву и одну цифру!"
        );
      }
    } else {
      return Promise.reject("Пароль должен содержать минимум 6 символов!");
    }
    return Promise.resolve();
  };

  return (
    <div className="InstallPasswordPage">
      <Form
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        style={{
          minWidth: 320,
          maxWidth: 600,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        validateMessages={validateMessages}
        layout="vertical"
      >
        <Form.Item
          label="Username"
          name="username"
          rules={[
            {
              required: true,
              message: "Пожалуйста, введите ваш username!",
            },
            {
              validator: validateUsername,
            },
          ]}
        >
          <Input /> 
        </Form.Item>
        {errText && <span style={{ color: "red" }}> {errText}</span>}

        <Form.Item
          label="Пароль"
          name="password"
          rules={[
            {
              required: true,
              message: "Пожалуйста, введите пароль!",
            },
            {
              validator: validatePassword,
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            loading={loading}
            size="large"
            className="ireceipt-order"
            htmlType="submit"
            block
          >
            Отправить
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default InstallPasswordPage;
