import React, { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { PlusOutlined } from "@ant-design/icons";
import { Upload, Button, Form } from "antd";

import "./CreateOrderPageStyles.css";
import { useTelegram } from "../../hooks/useTelegram";
import { CONFIG } from "../../constants/config";
import { ChInput } from "../../components/ChInput/ChInput";
import { ChInputTextArea } from "../../components/ChInputTextArea/ChInputTextArea";

export default function CreateOrderPage() {
  const [activeFormIndex, setActiveFormIndex] = useState(0);
  const [loadings, setLoadings] = useState(false);
  const { tg, onClose } = useTelegram();
  const [searchParams] = useSearchParams();
  const [orderId, setOrderId] = useState(null);

  const chatId = searchParams.get("chat_id");
  const apiKey = searchParams.get("api_key");
  tg.MainButton.hide();

  const [details, setDetails] = useState([]);

  const activeDetails = useMemo(
    () => details.filter((el) => !el?.$removed),
    [details]
  );
  const totalDetails = useMemo(() => activeDetails.length, [activeDetails]);

  useEffect(() => {
    if (!orderId) {
      axios
        .post(`${CONFIG.CLIENT_API_URL}/temp/orders?api_key=${apiKey}`, { chatId })
        .then(({ data: res }) => {
          setOrderId(res.order?.id);
          setDetails([res.orderDetail]);
          console.log("ress", res);
        });
    }
  }, []);

  const handleUpdateDetail = (value, { name, id }) => {
    axios.put(`${CONFIG.CLIENT_API_URL}/temp/orders/detail/${id}?api_key=${apiKey}`, {
      [name]: value,
      detailId: id,
      chatId,
    });
  };

  const onChangedata = (e, detailId) => {
    let value = e.target.value;
    const name = e.target.name;
    let newVal = value;

    if (name === "quantity") {
      value = parseInt(value) || 0;
    }

    if (name === "price") {
      newVal = !Number.isNaN(parseFloat(value)) ? parseFloat(value) : 0;
    }
    setDetails((prev) => {
      return [
        ...prev.map((el) => {
          if (el.id === detailId) {
            el[name] = value;
          }
          return el;
        }),
      ];
    });
  };

  const handleAddDetailForm = () => {
    axios
      .post(`${CONFIG.CLIENT_API_URL}/temp/orders/detail?api_key=${apiKey}`, {
        chatId,
      })
      .then(({ data: res }) => {
        setDetails([...details, res.orderDetail]);
        setActiveFormIndex(activeFormIndex + 1);
        console.log("ress1", res);
      });
  };

  const handleRemoveDetailForm = (e) => {
    e.preventDefault();
    if (totalDetails <= 1) {
      return;
    }
    const detail = details.find((el, index) => index === activeFormIndex);
    axios
      .delete(
        `${CONFIG.CLIENT_API_URL}/temp/orders/detail/${detail.id}?api_key=${apiKey}&chatId=${chatId}`
      )
      .then(({ data: res }) => {
        setDetails((prev) => [
          ...prev.filter((el) => el.id !== res.orderDetail.id),
        ]);
        if (activeFormIndex > 0) {
          setActiveFormIndex(activeFormIndex - 1);
        }
      });
  };

  const handlePrevDetailForm = () => {
    if (activeFormIndex > 0) {
      setActiveFormIndex(activeFormIndex - 1);
    }
  };

  const handleNextDetailForm = () => {
    if (activeFormIndex + 1 < totalDetails) {
      setActiveFormIndex(activeFormIndex + 1);
    }
  };

  const onSendData = (data) => {
    tg.sendData(JSON.stringify(data));
  };

  const sendFileToApi = () => {
    const data = new FormData();
    setLoadings(true);

    data.append("chatId", chatId);
    axios
      .post(`${CONFIG.CLIENT_API_URL}/temp/orders/save?api_key=${apiKey}`, data)
      .then(({ data: res }) => {
        onSendData(res.order.id);
        onClose();
      })
      .finally(() => {
        setLoadings(false);
      });
  };

  const handleChangeImage = (info, detailId) => {
    if (info.file.status === "uploading") {
      setLoadings(true);
      return;
    }
    if (info.file.status === "done") {
      setLoadings(false);
      axios
        .put(
          `${CONFIG.CLIENT_API_URL}/temp/orders/detail/${detailId}?api_key=${apiKey}`,
          { image: info.file.response }
        )
        .then(({ data: res }) => {
          console.log("ress3", res);
          setDetails((prev) => {
            const detail = prev.find((el) => el.id === res.orderDetail?.id);
            detail.image = res.orderDetail.image;
            return [...prev];
          });
        });
    }
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Загрузить изображение
      </div>
    </div>
  );

  return (
    <div className="detail-form-container">
      {activeDetails?.map((detail, index) => {
        return (
          <div
            className={`detail-form fade ${
              index === activeFormIndex ? "active" : ""
            }`}
            key={`detail_key_${detail.id}`}
          >
            <h3 className="order-title">Новый заказ</h3>
            <Form layout={"vertical"}>
              <ChInput
                label="Ссылка на товар*"
                name="link"
                placeholder="Ссылка"
                className={"detail-form-input ym-record-keys"}
                onChange={(ev) => onChangedata(ev, detail.id)}
                value={detail.link || ""}
                objectId={detail.id}
                onSave={handleUpdateDetail}
              />
              <ChInput
                label="Количество товара*"
                name="quantity"
                type="text"
                className={"detail-form-input ym-record-keys"}
                placeholder={"Количество"}
                onChange={(ev) => onChangedata(ev, detail.id)}
                value={detail.quantity}
                objectId={detail.id}
                onSave={handleUpdateDetail}
              />
              <ChInput
                label="Характеристика (Размер, цвет , тд)"
                name="size"
                className={"detail-form-input ym-record-keys"}
                type="text"
                placeholder={"Размер"}
                onChange={(ev) => onChangedata(ev, detail.id)}
                value={detail.size}
                objectId={detail.id}
                onSave={handleUpdateDetail}
              />
              <ChInput
                label="Цена товара"
                name="price"
                className={"detail-form-input ym-record-keys"}
                type="text"
                placeholder={"Цена"}
                onChange={(ev) => onChangedata(ev, detail.id)}
                value={detail.price}
                objectId={detail.id}
                onSave={handleUpdateDetail}
              />

              <Form.Item label="Комментарии к товару">
                <ChInputTextArea
                  rows={4}
                  name="description"
                  placeholder="Комментарии"
                  className="ym-record-keys"
                  maxLength={250}
                  onChange={(ev) => onChangedata(ev, detail.id)}
                  value={detail.description}
                  onSave={handleUpdateDetail}
                  objectId={detail.id}
                  showCount={true}
                />
              </Form.Item>

              <div className="file_upload">
                {detail.image ? <img src={detail.image} alt="image" /> : null}
                <Upload
                  action={CONFIG.FILE_SERVICE_API_URL}
                  listType="picture-card"
                  accept="image/*"
                  showUploadList={false}
                  onChange={(info) => handleChangeImage(info, detail.id)}
                  maxCount={1}
                >
                  {uploadButton}
                </Upload>
              </div>
            </Form>
          </div>
        );
      })}

      <div className="detail-form-footer">
        <div>
          {totalDetails > 1 && (
            <button className="remove-btn" onClick={handleRemoveDetailForm}>
              Удалить
            </button>
          )}
        </div>
        <div className="actions">
          {activeFormIndex > 0 && (
            <button onClick={handlePrevDetailForm}>&laquo; Пред.</button>
          )}
          {activeFormIndex + 1 !== totalDetails && (
            <button onClick={handleNextDetailForm} className="action-add">
              След. &raquo;
            </button>
          )}
          {activeFormIndex + 1 === totalDetails && (
            <button
              onClick={handleAddDetailForm}
              className="action-add"
              disabled={
                !details[activeFormIndex].link ||
                !details[activeFormIndex].quantity
              }
            >
              + Добавить товар к заказу
            </button>
          )}
        </div>
      </div>

      <Button
        type="primary"
        loading={loadings}
        onClick={() => sendFileToApi()}
        size="large"
        block
        disabled={
          !details[activeFormIndex]?.link || !details[activeFormIndex]?.quantity
        }
        className="create-order"
      >
        Заказать
      </Button>
    </div>
  );
}
