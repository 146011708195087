import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Image } from "antd";
import axios from "axios";

import "./ShowExchangeOrdersPageStyles.css";
import { NoutFound } from "../../components/NoutFound/NoutFound";
import { CONFIG } from "../../constants/config";

function ShowExchangeOrdersPage() {
  const [searchParams] = useSearchParams();
  const [exchange, setExchange] = useState([]);
  const [noutFound, setNoutFound] = useState(null);

  const { exchangeId } = useParams();

  const apiKey = searchParams.get("api_key");

  useEffect(() => {
    if (exchangeId) {
      axios
        .get(
          `${CONFIG.CLIENT_API_URL}/exchange/${exchangeId}?api_key=${apiKey}&is_show=show`
        )
        .then((res) => {
          if (res.data?.exchange) {
            setExchange(res.data?.exchange);
            setNoutFound(null);
          } else {
            setNoutFound(res.data?.message || "Ничего не найдено");
          }
        });
    }
  }, [exchangeId]);

  return (
    <>
      {noutFound ? (
        <NoutFound text={noutFound} />
      ) : (
        <div className="ShowOrdersPage">
          <div className="ShowOrdersPage-cards">
            <h4>
              Статус заявки:{" "}
              <span>{exchange?.ExchangeOrderStatus?.additionalTitle}</span>
            </h4>
            <h4>
              Итого:{" "}
              <span>
                {exchange?.amount} {exchange?.currency}
              </span>
            </h4>
            {exchange?.paymentType !== "bankcard" ? (
              <div className="ShowOrdersPage-card">
                <div className="ShowOrdersPage-card-border-left"></div>
                <ul className="ShowOrdersPage-card-content">
                  <li>
                    <span>Способ оплаты:</span>
                    <span>{String(exchange?.paymentType).toUpperCase()}</span>
                  </li>
                  <li>
                    <span>ФИО:</span>
                    <span>{exchange?.fullname}</span>
                  </li>
                  <li>
                    <span>Телефон:</span>
                    <span>{exchange?.phone}</span>
                  </li>
                  <li>
                    <span>Валюта:</span>
                    <span>{exchange?.currency}</span>
                  </li>
                  <li>
                    <span>Кол-во Юань:</span>
                    <span>{exchange?.count}</span>
                  </li>
                  <li className="qrcode">
                    <span>QR код:</span>
                    <span>
                      <Image width={200} src={exchange.qrcode} />
                    </span>
                  </li>
                  {exchange.clientReceipt ? (
                    <li className="qrcode">
                      <span>Квитанция:</span>
                      <span>
                        <Image width={200} src={exchange.clientReceipt} />
                      </span>
                    </li>
                  ) : null}

                  {exchange.adminReceipt ? (
                    <li className="qrcode">
                      <span>Админ Квитанция:</span>
                      <span>
                        <Image width={200} src={exchange.adminReceipt} />
                      </span>
                    </li>
                  ) : null}
                </ul>
              </div>
            ) : (
              <div className="ShowOrdersPage-card">
                <div className="ShowOrdersPage-card-border-left"></div>
                <ul className="ShowOrdersPage-card-content">
                  <li>
                    <span>Способ оплаты:</span>
                    <span>{String(exchange?.paymentType).toUpperCase()}</span>
                  </li>
                  <li>
                    <span>Название банка:</span>
                    <span>{exchange?.bankname}</span>
                  </li>
                  <li>
                    <span>Имя владельца карты:</span>
                    <span>{exchange?.cardholder}</span>
                  </li>
                  <li>
                    <span>Номер карты:</span>
                    <span>{exchange?.cardnumber}</span>
                  </li>
                  <li>
                    <span>Валюта:</span>
                    <span>{exchange?.currency}</span>
                  </li>
                  <li>
                    <span>Кол-во Юань:</span>
                    <span>{exchange?.count}</span>
                  </li>

                  {exchange.clientReceipt ? (
                    <li className="qrcode">
                      <span>Квитанция перевода:</span>
                      <span>
                        <Image width={200} src={exchange.clientReceipt} />
                      </span>
                    </li>
                  ) : null}

                  {exchange.adminReceipt ? (
                    <li className="qrcode">
                      <span>Квитанция получения:</span>
                      <span>
                        <Image width={200} src={exchange.adminReceipt} />
                      </span>
                    </li>
                  ) : null}
                </ul>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default ShowExchangeOrdersPage;
