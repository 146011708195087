import React, { useState } from "react";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import { Button, Input, Form } from "antd";
import PhoneInput from "react-phone-input-2";

import "react-phone-input-2/lib/style.css";
import "./style.css";

import { useTelegram } from "../../../../hooks/useTelegram";
import { CONFIG } from "../../../../constants/config";

const FormRegister = () => {
  const [auth, setAuth] = useState({
    user_name: "",
    surname: "",
    phone: "",
    address: "",
    countryCode: "",
    country: "",
    street: "",
    city: "",
  });

  const [loadings, setLoadings] = useState(false);
  const { tg, onClose } = useTelegram();
  const [searchParams] = useSearchParams();

  const chatId = searchParams.get("chat_id");
  const apiKey = searchParams.get("api_key");
  const username = searchParams.get("username");

  tg.MainButton.hide();

  const onSendData = (data) => {
    tg.sendData(JSON.stringify(data));
  };

  const onChangeAuth = (e) => {
    setAuth({ ...auth, [e.target.name]: e.target.value });
  };

  const handleSubmitRegistration = () => {
    setLoadings(true);

    const dataForm = {
      name: auth.user_name,
      surname: auth.surname,
      phone: auth.phone,
      address: `${auth.country} | ${auth.city} | ${auth.street}`,
      countryCode: auth.countryCode,
      chatId,
      tgUserName: username,
    };

    if (!dataForm.address || !dataForm.name || !dataForm.phone) {
      return;
    }

    axios
      .post(`${CONFIG.API_URL}/register?api_key=${apiKey}`, dataForm)
      .then(({ data }) => {
        onSendData(data);
        setLoadings(false);
        onClose();
      });
  };

  return (
    <div className={"form"}>
      <h3>Заполните все поля и нажмите кнопку отправить</h3>

      <Form layout={"vertical"}>
        <Form.Item label="Имя*">
          <Input
            name="user_name"
            className={"input ym-record-keys"}
            type="text"
            placeholder={"Имя"}
            onChange={onChangeAuth}
          />
        </Form.Item>
        <Form.Item label="Фамилия*">
          <Input
            name="surname"
            className={"input ym-record-keys"}
            type="text"
            placeholder={"Фамилия"}
            onChange={onChangeAuth}
          />
        </Form.Item>
        <Form.Item label="Номер телефон*">
          <PhoneInput
            country={"ru"}
            placeholder={"Номер телефон"}
            enableSearch={true}
            enableAreaCodes={true}
            onChange={(phone, country) =>
              setAuth({ ...auth, phone, countryCode: country.countryCode })
            }
          />
        </Form.Item>

        <Form.Item label="Страна*">
          <Input
            name="country"
            className={"input ym-record-keys"}
            type="text"
            placeholder={"Адресс"}
            onChange={onChangeAuth}
          />
        </Form.Item>

        <Form.Item label="Город*">
          <Input
            name="city"
            className={"input ym-record-keys"}
            type="text"
            placeholder={"Адресс"}
            onChange={onChangeAuth}
          />
        </Form.Item>

        <Form.Item label="Улица - дом*">
          <Input
            name="street"
            className={"input ym-record-keys"}
            type="text"
            placeholder={"Адресс"}
            onChange={onChangeAuth}
          />
        </Form.Item>
      </Form>

      <Button
        type="primary"
        loading={loadings}
        onClick={handleSubmitRegistration}
        size="large"
        block
        disabled={
          !auth.user_name ||
          !auth.country ||
          !auth.city ||
          !auth.street ||
          !auth.phone
        }
        className="registration-btn"
      >
        Оправить
      </Button>
      <div className="auth-space"></div>
    </div>
  );
};

export default FormRegister;
