import React, { useEffect, useState } from "react";
import { Input, Form } from "antd";

import useInputDebounce from "../../hooks/useInputDebounse";

export const ChInput = ({
  name,
  value,
  label,
  placeholder,
  onChange,
  onSave,
  objectId,
  className,
}) => {
  const [inputVal, setInputVal] = useState(value);
  const [initVal, setInitVal] = useState(1);

  const debouncedVal = useInputDebounce(inputVal, 300);

  const handleChange = (ev) => {
    let newVal = ev.target.value;
    if (name === "quantity") {
      newVal = parseInt(newVal) || 0;
    }
    setInputVal(newVal);
    onChange(ev);
  };

  useEffect(() => {
    if (debouncedVal !== undefined && initVal !== 1) {
      let newVal = debouncedVal;
      if (name === "price") {
        newVal = !Number.isNaN(parseFloat(newVal)) ? parseFloat(newVal) : 0;
      }
      onSave &&
        onSave(newVal, {
          name,
          id: objectId,
        });
    } else {
      setInitVal(initVal + 1);
    }
  }, [debouncedVal]);

  return (
    <Form.Item label={label}>
      <Input
        name={name}
        value={inputVal}
        onChange={handleChange}
        placeholder={placeholder}
        className={className}
      />
    </Form.Item>
  );
};
