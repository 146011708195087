import React, { useState } from "react";
import { Button, Input, Form } from "antd";
import { useSearchParams } from "react-router-dom";
import axios from "axios";

import { useTelegram } from "../../hooks/useTelegram";

import "./FaqsPageStyles.css";
import { CONFIG } from "../../constants/config";

const FaqsPage = () => {
  const [faq, setFaq] = useState({
    question: "",
    answers: "",
  });
  const [loading, setLoading] = useState(false);

  const [searchParams] = useSearchParams();
  const { tg, onClose } = useTelegram();
  tg.MainButton.hide();
  const apiKey = searchParams.get("api_key");

  const onSendData = () => {
    tg.sendData(JSON.stringify(faq));
  };

  const handleChageFaq = (ev) => {
    setFaq({ ...faq, [ev.target.name]: ev.target.value });
  };

  const handleSendForm = () => {
    setLoading(true);
    axios.post(`${CONFIG.API_URL}/faq?api_key=${apiKey}`, faq).then((res) => {
      onSendData();
      setLoading(false);
      onClose();
    });
  };

  return (
    <>
      <div className="FaqPage">
        <div className="FaqPage-container">
          <Form layout={"vertical"}>
            <Form.Item label="Вопрос*">
              <Input
                name="question"
                placeholder="Вопрос"
                className={"faq-form-input ym-record-keys"}
                value={faq?.question || ""}
                onChange={(ev) => handleChageFaq(ev)}
              />
            </Form.Item>
            <Form.Item label="Ответ*">
              <Input.TextArea
                rows={5}
                name="answers"
                placeholder="Ответ ym-record-keys"
                value={faq?.answers || ""}
                onChange={(ev) => handleChageFaq(ev)}
                maxLength={250}
                showCount
              />
            </Form.Item>

            <Button
              type="primary"
              loading={loading}
              onClick={() => handleSendForm()}
              size="large"
              block
              disabled={!faq.question || !faq.answers}
              className="faq-from-btn"
            >
              Сохранить
            </Button>
          </Form>
        </div>
      </div>
    </>
  );
};

export default FaqsPage;
