import React, { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { Form, Select, Input, Space, Upload, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import PhoneInput from "react-phone-input-2";

import { useTelegram } from "../../hooks/useTelegram";

import "./ExchangePageStyles.css";
import { YuIcon } from "../CurrenciesPage/icons/CurrenciesIcon";
import { CONFIG } from "../../constants/config";

const ExchangePage = () => {
  const [qrcode, setQrcode] = useState("");
  const [phone, setPhone] = useState("");
  const [fullname, setFullname] = useState("");
  const [bankname, setBankname] = useState("");
  const [cardnumber, setCardnumber] = useState("");
  const [cardholder, setCardholder] = useState("");
  const [currency, setCurrency] = useState("RUB");
  const [count, setCount] = useState(0);
  const [selectedPayment, setSelectedPayment] = useState("alipay");
  const [currencies, setCurrencies] = useState([]);
  const [errors, setErrors] = useState({});

  const [loading, setLoading] = useState(false);

  const [searchParams] = useSearchParams();
  const apiKey = searchParams.get("api_key");
  const chatId = searchParams.get("chat_id");

  const { tg, onClose } = useTelegram();
  tg.MainButton.hide();

  useEffect(() => {
    axios
      .get(`${CONFIG.CLIENT_API_URL}/exchange-currencies?api_key=${apiKey}`)
      .then(({ data }) => {
        setCurrencies(data);
      });
  }, []);

  const result = useMemo(() => {
    if (!count || count < 100) {
      return "-";
    }
    let amount = null;
    currencies.forEach((el) => {
      if (
        count >= Number(el.from) &&
        count <= Number(el.to) &&
        el.type === currency
      ) {
        amount = Number(el.value) * count;
      }
    });
    if (!amount) {
      return "Договорная";
    }
    return amount;
  }, [count, currencies, currency]);

  const onSendData = (data) => {
    tg.sendData(JSON.stringify(data));
  };

  const validateFields = () => {
    const error = {};

    if (!count || count < 100) {
      error.count = true;
    }

    if (["alipay", "wechat"].includes(selectedPayment)) {
      if (!phone) {
        error.phone = true;
      }
      if (!fullname) {
        error.fullname = true;
      }
      if (!qrcode) {
        error.qrcode = true;
      }
    }

    if (selectedPayment === "bankcard") {
      if (!bankname) {
        error.bankname = true;
      }
      if (!cardnumber) {
        error.cardnumber = true;
      }
      if (!cardholder) {
        error.cardholder = true;
      }
    }

    setErrors({ ...error });
    if (Object.keys(error).length) {
      return false;
    }

    return true;
  };

  const onSubmitForm = () => {
    if (!validateFields()) {
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append("paymentType", selectedPayment);
    formData.append("phone", phone);
    formData.append("fullname", fullname);
    formData.append("bankname", bankname);
    formData.append("cardnumber", cardnumber);
    formData.append("cardholder", cardholder);
    formData.append("currency", currency);
    formData.append("count", count);
    formData.append("qrcode", qrcode);
    formData.append("chatId", chatId);
    formData.append("amount", result);
    axios
      .post(`${CONFIG.CLIENT_API_URL}/exchange?api_key=${apiKey}`, formData)
      .then(({ data }) => {
        onSendData(data.id);
        onClose();
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChangePayment = (value) => {
    setQrcode("");
    setPhone("");
    setFullname("");
    setBankname("");
    setCardnumber("");
    setCardholder("");
    setSelectedPayment(value);
  };

  const handleChangeCurrency = (value) => {
    setCurrency(value);
  };

  const handleChangeQrcode = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      setLoading(false);
      setQrcode(info.file.response);
    }
  };

  const paymentMethod = (val) => {
    if (val === "alipay") {
      return (
        <>
          <Form.Item label="Номер телефон">
            <PhoneInput
              country={"ru"}
              placeholder={"Номер телефон"}
              enableSearch={true}
              enableAreaCodes={true}
              value={phone}
              onChange={(phone, country) => setPhone(phone)}
            />
            {errors.phone && (
              <div className="form-error">
                *Номер телефона обязательное поле
              </div>
            )}
          </Form.Item>
          <Form.Item label="ФИО">
            <Input
              name="fullname"
              value={fullname}
              onChange={(ev) => setFullname(ev.target.value)}
            />
            {errors.fullname && (
              <div className="form-error">*ФИО обязательное поле</div>
            )}
          </Form.Item>
          <Form.Item label="QR код">
            <Space direction="horizontal" size="middle">
              <div className="preview_img">
                {qrcode ? <img src={qrcode} alt="qrcode" /> : null}
              </div>
              <Upload
                action={CONFIG.FILE_SERVICE_API_URL}
                listType="picture-card"
                showUploadList={false}
                onChange={(ev) => handleChangeQrcode(ev)}
                accept="image/*"
                maxCount={1}
              >
                <div>
                  <PlusOutlined />
                  <div
                    style={{
                      marginTop: 8,
                    }}
                  >
                    Загрузить QR код
                  </div>
                </div>
              </Upload>

              {errors.qrcode && (
                <div className="form-error">*QR код обязательное поле</div>
              )}
            </Space>
          </Form.Item>
        </>
      );
    }
    if (val === "wechat") {
      return (
        <>
          <Form.Item label="Номер телефон">
            <PhoneInput
              country={"ru"}
              placeholder={"Номер телефон"}
              enableSearch={true}
              enableAreaCodes={true}
              value={phone}
              onChange={(phone, country) => setPhone(phone)}
            />
            {errors.phone && (
              <div className="form-error">
                *Номер телефона обязательное поле
              </div>
            )}
          </Form.Item>
          <Form.Item label="ФИО">
            <Input
              name="fullname"
              value={fullname}
              onChange={(ev) => setFullname(ev.target.value)}
            />
            {errors.fullname && (
              <div className="form-error">*ФИО обязательное поле</div>
            )}
          </Form.Item>
          <Form.Item label="QR код">
            <Space direction="horizontal" size="middle">
              <div className="preview_img">
                {qrcode ? <img src={qrcode} alt="qrcode" /> : null}
              </div>
              <Upload
                action={CONFIG.FILE_SERVICE_API_URL}
                listType="picture-card"
                showUploadList={false}
                onChange={(ev) => handleChangeQrcode(ev)}
                accept="image/*"
                maxCount={1}
              >
                <div>
                  <PlusOutlined />
                  <div
                    style={{
                      marginTop: 8,
                    }}
                  >
                    Загрузить QR код
                  </div>
                </div>
              </Upload>

              {errors.qrcode && (
                <div className="form-error">*QR код обязательное поле</div>
              )}
            </Space>
          </Form.Item>
        </>
      );
    }
    if (val === "bankcard") {
      return (
        <>
          <Form.Item label="Название банка">
            <Input
              name="bankname"
              value={bankname}
              onChange={(ev) => setBankname(ev.target.value)}
            />
            {errors.bankname && (
              <div className="form-error">
                *Название банка обязательное поле
              </div>
            )}
          </Form.Item>
          <Form.Item label="Номер карты">
            <Input
              name="cardnumber"
              value={cardnumber}
              onChange={(ev) => setCardnumber(ev.target.value)}
            />
            {errors.cardnumber && (
              <div className="form-error">*Номер карты обязательное поле</div>
            )}
          </Form.Item>
          <Form.Item label="Имя владельца карты">
            <Input
              name="cardholder"
              value={cardholder}
              onChange={(ev) => setCardholder(ev.target.value)}
            />
            {errors.cardholder && (
              <div className="form-error">
                *Имя владельца карты обязательное поле
              </div>
            )}
          </Form.Item>
        </>
      );
    }
  };

  return (
    <div className="ExchangePage">
      <Form layout="vertical">
        <Form.Item label="Способ оплаты">
          <Select
            defaultValue={selectedPayment}
            style={{ width: "100%" }}
            onChange={handleChangePayment}
            options={[
              { value: "alipay", label: "AliPay" },
              { value: "wechat", label: "Wechat" },
              { value: "bankcard", label: "Bank Card" },
            ]}
          />
        </Form.Item>
        {paymentMethod(selectedPayment)}

        <div className="ExchangePage-cuurency">
          <Form.Item label="Валюта">
            <Select
              defaultValue="RUB"
              style={{ width: "100%" }}
              onChange={handleChangeCurrency}
              options={[
                { value: "RUB", label: "RUB" },
                { value: "TJS", label: "TJS" },
              ]}
            />
          </Form.Item>
          <Form.Item
            label={
              <Space direction="horizontal" align="center">
                <span>Кол-во Юань </span>
                <YuIcon />
              </Space>
            }
          >
            <Input
              name="count"
              type="number"
              value={count}
              onChange={(ev) => setCount(ev.target.value)}
              min={100}
            />

            {errors.count && (
              <div className="form-error">*Минимальное количество 100 Юань</div>
            )}
          </Form.Item>

          <div>
            Сумма: {result} {currency}
          </div>
        </div>
        <div className="ExchangePage-action">
          <Button
            type="primary"
            loading={loading}
            onClick={() => onSubmitForm()}
            size="large"
            className="receipt-order"
            block
          >
            Отправить
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default ExchangePage;
