import React, { useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import axios from "axios";
import { Upload, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import './DeliveryReceiptPageStyles.css'
import { useTelegram } from "../../hooks/useTelegram";
import { CONFIG } from "../../constants/config";

const DeliveryReceiptPage = () => {
  const [picture, setPicture] = useState("");
  const [loading, setLoading] = useState(false);

  const [searchParams] = useSearchParams();
  const { tg, onClose } = useTelegram();

  const chatId = searchParams.get("chat_id");
  const apiKey = searchParams.get("api_key");
  const { orderId } = useParams();
  tg.MainButton.hide();

  const handleChangeImage = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      setLoading(false);
      setPicture(info.file.response);
    }
  };

  const onSendData = (data) => {
    tg.sendData(JSON.stringify(data));
  };

  const sendFileToApi = () => {
    const data = new FormData();
    data.append("image", picture);
    data.append("chatId", chatId);
    setLoading(true);
    axios

      .post(
        `${CONFIG.API_URL}/order/${orderId}/delivery-receipt?api_key=${apiKey}`,
        data
      )
      .then(({ data: res }) => {
        onSendData(res);
        setLoading(false);
        onClose();
      });
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Загрузить изображение
      </div>
    </div>
  );

  return (
    <div>
      <div className="DeliveryReceiptPage">
        <div className="DeliveryReceiptPage-wrapper">
          <div className="DeliveryReceiptPage-title">Добавить квитанцию</div>
          <div className="file_upload">
            {picture ? <img src={picture} alt="picture" /> : null}
            <Upload
              action={CONFIG.FILE_SERVICE_API_URL}
              listType="picture-card"
              showUploadList={false}
              onChange={(ev) => handleChangeImage(ev)}
              accept="image/*"
              maxCount={1}
            >
              {uploadButton}
            </Upload>
          </div>
          <div className="DeliveryReceiptPage-action">
            <Button
              type="primary"
              loading={loading}
              onClick={() => sendFileToApi()}
              size="large"
              disabled={!picture}
              className="receipt-order"
              block
            >
              Отправить
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeliveryReceiptPage;
