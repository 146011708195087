import React, { useEffect, useMemo, useState } from "react";
import { useTelegram } from "../../hooks/useTelegram";
import { useParams, useSearchParams } from "react-router-dom"; 
import axios from "axios";

import { PlusOutlined } from "@ant-design/icons";
import { Upload, Button, Form } from "antd";

import "./UpdateOrderPageStyles.css";
import { NoutFound } from "../../components/NoutFound/NoutFound";
import { CONFIG } from "../../constants/config"; 
import { ChInput } from "../../components/ChInput/ChInput";
import { ChInputTextArea } from "../../components/ChInputTextArea/ChInputTextArea";

export default function UpdateOrderPage() {
  const [activeFormIndex, setActiveFormIndex] = useState(0);
  const [loadings, setLoadings] = useState(false);
  const [loading, setLoading] = useState(false);
  const [noutFound, setNoutFound] = useState(null);
  const [searchParams] = useSearchParams(); 

  const { tg, onClose } = useTelegram();
  tg.MainButton.hide();

  const { orderId } = useParams();

  const chatId = searchParams.get("chat_id");
  const apiKey = searchParams.get("api_key");

  const [details, setDetails] = useState([]);

  const activeDetails = useMemo(
    () => details.filter((el) => !el?.$removed),
    [details]
  );
  const totalDetails = useMemo(() => activeDetails.length, [activeDetails]);

  const onSendData = (dataResult) => {
    tg.sendData(JSON.stringify(dataResult));
  };

  const handleUpdateDetail = (value, { name, id }, isTemp = null) => {
    let url = `${CONFIG.CLIENT_API_URL}/temp/orders/exs/detail/${id}?api_key=${apiKey}`;
    if (isTemp) {
      url = `${CONFIG.CLIENT_API_URL}/temp/orders/detail/${id}?api_key=${apiKey}`;
    }
    axios.put(url, {
      [name]: value,
      detailId: id,
      chatId,
    });
  };

  const onChangedata = (e, detailId, isTemp) => {
    let value = e.target.value;
    const name = e.target.name;
    let newVal = value;

    if (name === "quantity") {
      value = parseInt(value) || 0;
    }

    if (name === "price") {
      newVal = !Number.isNaN(parseFloat(value)) ? parseFloat(value) : 0;
    }

    setDetails((prev) => {
      const detail = prev.find((el) => el.id === detailId);
      detail[name] = value;
      return [...prev];
    });
  };

  const handleAddDetailForm = () => {
    axios
      .post(`${CONFIG.CLIENT_API_URL}/temp/orders/exs/${orderId}?api_key=${apiKey}`, {
        chatId,
      })
      .then(({ data: res }) => {
        setDetails([...details, res.orderDetail]);
        setActiveFormIndex(activeFormIndex + 1);
      });
  };

  const handleRemoveDetailForm = (e) => {
    e.preventDefault();
    if (totalDetails <= 1) {
      return;
    }
    const detail = details.find((el, index) => index === activeFormIndex);
    let url = `${CONFIG.CLIENT_API_URL}/temp/orders/exs/detail/${detail.id}?api_key=${apiKey}&chatId=${chatId}`;
    if (detail.isTemp) {
      url = `${CONFIG.CLIENT_API_URL}/temp/orders/detail/${detail.id}?api_key=${apiKey}&chatId=${chatId}`;
    }
    axios.delete(url).then(({ data: res }) => {
      setDetails((prev) => [
        ...prev.filter((el) => el.id !== res.orderDetail.id),
      ]);
      if (activeFormIndex > 0) {
        setActiveFormIndex(activeFormIndex - 1);
      }
    });
  };

  const handlePrevDetailForm = () => {
    if (activeFormIndex > 0) {
      setActiveFormIndex(activeFormIndex - 1);
    }
  };

  const handleNextDetailForm = () => {
    if (activeFormIndex + 1 < totalDetails) {
      setActiveFormIndex(activeFormIndex + 1);
    }
  };

  const sendFileToApi = () => {
    const data = new FormData();
    setLoadings(true);

    data.append("chatId", chatId);
    axios
      .post(
        `${CONFIG.CLIENT_API_URL}/temp/orders/exs/${orderId}/save?api_key=${apiKey}`,
        data
      )
      .then(({ data: res }) => {
        onSendData(res.order.id);
        onClose();
      })
      .finally(() => {
        setLoadings(false);
      });
  };

  useEffect(() => {
    if (orderId) {
      setLoading(true);
      axios
        .get(`${CONFIG.CLIENT_API_URL}/temp/order/${orderId}?api_key=${apiKey}`)
        .then((res) => {
          if (res.data?.order && res.data?.order?.OrderDetail?.length) {
            setDetails(res.data?.order?.OrderDetail);
            setNoutFound(null);
          } else {
            setNoutFound(res.data?.message || "Ничего не найдено");
          }
        })
        .finally(() => setLoading(false));
    }
  }, [orderId]);

  const handleChangeImage = (info, detailId, isTemp = null) => {
    if (info.file.status === "uploading") {
      setLoadings(true);
      return;
    }
    if (info.file.status === "done") {
      setLoadings(false);
      let url = `${CONFIG.CLIENT_API_URL}/temp/orders/exs/detail/${detailId}?api_key=${apiKey}`;
      if (isTemp) {
        url = `${CONFIG.CLIENT_API_URL}/temp/orders/detail/${detailId}?api_key=${apiKey}`;
      }
      axios.put(url, { image: info.file.response }).then(({ data: res }) => {
        setDetails((prev) => {
          const detail = prev.find((el) => el.id === res.orderDetail?.id);
          detail.image = res.orderDetail.image;
          return [...prev];
        });
      });
    }
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Загрузить изображение
      </div>
    </div>
  );

  return (
    <>
      {noutFound || loading ? (
        <NoutFound text={noutFound} />
      ) : (
        <div className="detail-form-container">
          {activeDetails?.map((detail, index) => {
            return (
              <div
                className={`detail-form fade ${
                  index === activeFormIndex ? "active" : ""
                }`}
                key={`detail_key_${detail.id}`}
              >
                <h3>Заполните все поля и нажмите кнопку отправить</h3>
                <Form layout={"vertical"}>
                  <ChInput
                    label="Ссылка на товар*"
                    name="link"
                    placeholder="Ссылка"
                    className={"detail-form-input ym-record-keys"}
                    onChange={(ev) =>
                      onChangedata(ev, detail.id, detail.isTemp)
                    }
                    value={detail.link || ""}
                    objectId={detail.id}
                    onSave={(val, params) =>
                      handleUpdateDetail(val, params, detail.isTemp)
                    }
                  />

                  <ChInput
                    label="Количество товара*"
                    name="quantity"
                    type="text"
                    className={"detail-form-input ym-record-keys"}
                    placeholder={"Количество"}
                    onChange={(ev) =>
                      onChangedata(ev, detail.id, detail.isTemp)
                    }
                    value={detail.quantity}
                    objectId={detail.id}
                    onSave={(val, params) =>
                      handleUpdateDetail(val, params, detail.isTemp)
                    }
                  />

                  <ChInput
                    label="Характеристика (Размер, цвет , тд)"
                    name="size"
                    className={"detail-form-input ym-record-keys"}
                    type="text"
                    placeholder={"Размер"}
                    onChange={(ev) =>
                      onChangedata(ev, detail.id, detail.isTemp)
                    }
                    value={detail.size}
                    objectId={detail.id}
                    onSave={(val, params) =>
                      handleUpdateDetail(val, params, detail.isTemp)
                    }
                  />

                  <ChInput
                    label="Цена товара"
                    name="price"
                    className={"detail-form-input ym-record-keys"}
                    type="text"
                    placeholder={"Цена"}
                    onChange={(ev) =>
                      onChangedata(ev, detail.id, detail.isTemp)
                    }
                    value={detail.price}
                    objectId={detail.id}
                    onSave={(val, params) =>
                      handleUpdateDetail(val, params, detail.isTemp)
                    }
                  />

                  <Form.Item label="Комментарии к товару">
                    <ChInputTextArea
                      rows={4}
                      name="description"
                      placeholder="Комментарии"
                      className="ym-record-keys"
                      maxLength={250}
                      onChange={(ev) =>
                        onChangedata(ev, detail.id, detail.isTemp)
                      }
                      value={detail.description}
                      onSave={(val, params) =>
                        handleUpdateDetail(val, params, detail.isTemp)
                      }
                      objectId={detail.id}
                      showCount={true}
                    />
                  </Form.Item>

                  <div className="file_upload">
                    {detail.image ? (
                      <img src={detail.image} alt="image" />
                    ) : null}
                    <Upload
                      action={CONFIG.FILE_SERVICE_API_URL}
                      listType="picture-card"
                      showUploadList={false}
                      onChange={(ev) =>
                        handleChangeImage(ev, detail.id, detail.isTemp)
                      }
                      maxCount={1}
                    >
                      {uploadButton}
                    </Upload>
                  </div>
                </Form>
              </div>
            );
          })}

          <div className="detail-form-footer">
            <div>
              {totalDetails > 1 && (
                <button className="remove-btn" onClick={handleRemoveDetailForm}>
                  Удалить
                </button>
              )}
            </div>
            <div className="actions">
              {activeFormIndex > 0 && (
                <button onClick={handlePrevDetailForm}>&laquo; Пред.</button>
              )}
              {activeFormIndex + 1 !== totalDetails && (
                <button onClick={handleNextDetailForm} className="action-add">
                  След. &raquo;
                </button>
              )}
              {activeFormIndex + 1 === totalDetails && (
                <button
                  onClick={handleAddDetailForm}
                  className="action-add"
                  disabled={
                    !details[activeFormIndex]?.link ||
                    !details[activeFormIndex]?.quantity
                  }
                >
                  + Добавить товар к заказу
                </button>
              )}
            </div>
          </div>

          <Button
            type="primary"
            loading={loadings}
            onClick={() => sendFileToApi()}
            size="large"
            block
            disabled={
              !details[activeFormIndex]?.link ||
              !details[activeFormIndex]?.quantity
            }
          >
            Изменить
          </Button>
        </div>
      )}
    </>
  );
}
