import React, { useEffect, useState } from "react";
import { Button, Input, Form } from "antd";
import { useSearchParams } from "react-router-dom";
import axios from "axios";

import { useTelegram } from "../../hooks/useTelegram";

import "./CurrenciesPageStyles.css";

import { YuIcon, RuIcon, DolIcon, ExcnahgeIcom } from "./icons/CurrenciesIcon";
import { CONFIG } from "../../constants/config";

const CurrenciesPage = () => {
  const [currrencyRu, setCurrencyRu] = useState({});
  const [currrencyDRu, setCurrencyDRu] = useState({});
  const [currrencyTj, setCurrencyTj] = useState({});
  const [currrencyDT, setCurrencyDT] = useState({});
  const [currrencyET, setCurrencyET] = useState({});
  const [currrencyUSDT, setCurrencyUSDT] = useState({});
  const [currrencyUSDTYuan, setCurrencyUSDTYuan] = useState({});

  const [currrencyYuan, setCurrencyYuan] = useState({
    key: "first",
    title: "Юань",
    value: "1",
  });
  const [currrencyDolar, setCurrencyDolar] = useState({
    key: "third",
    title: "Доллар",
    value: "1",
  });
  const [currrencyTYuan, setCurrencyTYuan] = useState({
    key: "fifth",
    title: "Рубль",
    value: "1000",
  });

  const [currrencyDTj, setCurrencyDTj] = useState({
    key: "seven",
    title: "Доллар",
    value: "1",
  });

  const [currrencyETj, setCurrencyETj] = useState({
    key: "nine",
    title: "Доллар",
    value: "1",
  });

  const [loading, setLoading] = useState(false);
  const [loadData, setLoadData] = useState("");
  const [searchParams] = useSearchParams();

  const { tg, onClose } = useTelegram();
  tg.MainButton.hide();

  const apiKey = searchParams.get("api_key");
  const companyId = searchParams.get("company_id");

  const onSendDataToBot = (data) => {
    tg.sendData(JSON.stringify(data));
  };

  useEffect(() => {
    axios
      .get(
        `${CONFIG.API_URL}/currency?api_key=${apiKey}&companyId=${companyId}`
      )
      .then(({ data }) => {
        const parsedData = JSON.parse(data[0].data);
        setCurrencyYuan(parsedData[0]);
        setCurrencyRu(parsedData[1]);
        setCurrencyDolar(parsedData[2]);
        setCurrencyDRu(parsedData[3]);
        setCurrencyTYuan(parsedData[4]);
        setCurrencyTj(parsedData[5]);
        setCurrencyDT(parsedData[6]);
        setCurrencyDTj(parsedData[7]);
        setCurrencyET(parsedData[8]);
        setCurrencyETj(parsedData[9]);
        setCurrencyUSDT(parsedData[10]);
        setCurrencyUSDTYuan(parsedData[11]);
        setLoadData(data[0].id);
      });
  }, []);

  const handleChangeCurrencyRu = (ev) => {
    setCurrencyRu({
      key: "second",
      title: "Рубль",
      value: ev.target.value,
    });
  };

  const handleChangeCurrencyYuan = (ev) => {
    setCurrencyYuan({
      key: "first",
      title: "Юань",
      value: ev.target.value,
    });
  };

  const handleChangeCurrencyDolar = (ev) => {
    setCurrencyDolar({
      key: "third",
      title: "Доллар",
      value: ev.target.value,
    });
  };

  const handleChangeCurrencyDRu = (ev) => {
    setCurrencyDRu({
      key: "fourth",
      title: "Рубль",
      value: ev.target.value,
    });
  };

  const handleChangeCurrencyTYuan = (ev) => {
    setCurrencyTYuan({
      key: "fifth",
      title: "Юань",
      value: ev.target.value,
    });
  };

  const handleChangeCurrencyTj = (ev) => {
    setCurrencyTj({
      key: "sixth",
      title: "Сомони",
      value: ev.target.value,
    });
  };

  const onSendData = () => {
    setLoading(true);
    if (!loadData.length) {
      axios
        .put(
          `${CONFIG.API_URL}/currency?api_key=${apiKey}&companyId=${companyId}`,
          {
            data: JSON.stringify([
              currrencyYuan,
              currrencyRu,
              currrencyDolar,
              currrencyDRu,
              currrencyTYuan,
              currrencyTj,
              currrencyDT,
              currrencyDTj,
              currrencyET,
              currrencyETj,
              currrencyUSDT,
              currrencyUSDTYuan,
            ]),
            id: loadData,
          }
        )
        .then((res) => {
          onSendDataToBot(res.data);
          setLoading(false);
          onClose();
        });
    } else {
      axios
        .post(
          `${CONFIG.API_URL}/currency?api_key=${apiKey}&companyId=${companyId}`,
          {
            data: [
              currrencyYuan,
              currrencyRu,
              currrencyDolar,
              currrencyDRu,
              currrencyTYuan,
              currrencyTj,
              currrencyDT,
              currrencyDTj,
              currrencyET,
              currrencyETj,
              currrencyUSDT,
              currrencyUSDTYuan,
            ],
          }
        )
        .then(({ data }) => {
          onSendDataToBot(data);
          setLoading(false);
          onClose();
        });
    }
  };

  return (
    <div className="CurrenciesPage">
      <div className="CurrenciesPage-container">
        <Form layout={"vertical"}>
          <Form.Item label="Курс валют Юань*">
            <Input
              name="first"
              placeholder="Юань"
              className={"currency-form-input ym-record-keys"}
              prefix={<YuIcon />}
              value={currrencyYuan?.value || ""}
              onChange={(ev) => handleChangeCurrencyYuan(ev)}
            />
          </Form.Item>
          <div className="excnahgeCurrency">
            <ExcnahgeIcom />
          </div>
          <Form.Item label="Курс валют Рубль*">
            <Input
              name="second"
              placeholder="Рубль"
              className={"currency-form-input ym-record-keys"}
              prefix={<RuIcon />}
              value={currrencyRu?.value || ""}
              onChange={(ev) => handleChangeCurrencyRu(ev)}
            />
          </Form.Item>
          <div className="exch-hr"></div>
          <Form.Item label="Курс валют Доллар*">
            <Input
              name="third"
              placeholder="Доллар"
              className={"currency-form-input ym-record-keys"}
              prefix={<DolIcon />}
              value={currrencyDolar?.value || ""}
              onChange={(ev) => handleChangeCurrencyDolar(ev)}
            />
          </Form.Item>

          <div className="excnahgeCurrency">
            <ExcnahgeIcom />
          </div>
          <Form.Item label="Курс валют Рубль*">
            <Input
              name="fourth"
              placeholder="Рубль"
              className={"currency-form-input ym-record-keys"}
              prefix={<RuIcon />}
              value={currrencyDRu?.value || ""}
              onChange={(ev) => handleChangeCurrencyDRu(ev)}
            />
          </Form.Item>

          <div className="exch-hr"></div>
          <Form.Item label="Курс валют Юань*">
            <Input
              name="fifth"
              placeholder="Юань"
              className={"currency-form-input ym-record-keys"}
              prefix={<YuIcon />}
              value={currrencyTYuan?.value || ""}
              onChange={(ev) => handleChangeCurrencyTYuan(ev)}
            />
          </Form.Item>
          <div className="excnahgeCurrency">
            <ExcnahgeIcom />
          </div>
          <Form.Item label="Курс валют Сомони*">
            <Input
              name="sixth"
              placeholder="Сомони"
              className={"currency-form-input ym-record-keys"}
              prefix={<span className="tj-cur">TJS</span>}
              value={currrencyTj?.value || ""}
              onChange={(ev) => handleChangeCurrencyTj(ev)}
            />
          </Form.Item>
          {/* вв */}
          <div className="exch-hr"></div>
          <Form.Item label="Курс валют Доллар*">
            <Input
              name="fifth"
              placeholder="Доллар"
              className={"currency-form-input ym-record-keys"}
              prefix={<DolIcon />}
              value={currrencyDTj?.value || ""}
              onChange={(ev) =>
                setCurrencyDTj({
                  key: "seven",
                  title: "Доллар",
                  value: ev.target.value,
                })
              }
            />
          </Form.Item>
          <div className="excnahgeCurrency">
            <ExcnahgeIcom />
          </div>
          <Form.Item label="Курс валют Сомони*">
            <Input
              name="sixth"
              placeholder="Сомони"
              className={"currency-form-input ym-record-keys"}
              prefix={<span className="tj-cur">TJS</span>}
              value={currrencyDT?.value || ""}
              onChange={(ev) =>
                setCurrencyDT({
                  key: "eight",
                  title: "Сомони",
                  value: ev.target.value,
                })
              }
            />
          </Form.Item>

          {/* dd */}
          <div className="exch-hr"></div>

          <Form.Item label="Курс валют Юань*">
            <Input
              name="nine"
              placeholder="Юань"
              className={"currency-form-input ym-record-keys"}
              prefix={<YuIcon />}
              value={currrencyETj?.value || ""}
              onChange={(ev) =>
                setCurrencyETj({
                  key: "nine",
                  title: "Юань",
                  value: ev.target.value,
                })
              }
            />
          </Form.Item>
          <div className="excnahgeCurrency">
            <ExcnahgeIcom />
          </div>
          <Form.Item label="Курс валют Евро*">
            <Input
              name="eight"
              placeholder="Евро"
              className={"currency-form-input ym-record-keys"}
              prefix={<span className="tj-cur">EUR</span>}
              value={currrencyET?.value || ""}
              onChange={(ev) =>
                setCurrencyET({
                  key: "eight",
                  title: "Евро",
                  value: ev.target.value,
                })
              }
            />
          </Form.Item>

          {/* usdt */}
          <div className="exch-hr"></div>

          <Form.Item label="Курс валют Юань*">
            <Input
              name="eleven"
              placeholder="Юань"
              className={"currency-form-input ym-record-keys"}
              prefix={<YuIcon />}
              value={currrencyUSDTYuan?.value || ""}
              onChange={(ev) =>
                setCurrencyUSDTYuan({
                  key: "eleven",
                  title: "Юань",
                  value: ev.target.value,
                })
              }
            />
          </Form.Item>
          <div className="excnahgeCurrency">
            <ExcnahgeIcom />
          </div>
          <Form.Item label="Курс валют USDT*">
            <Input
              name="ten"
              placeholder="USDT"
              className={"currency-form-input ym-record-keys"}
              prefix={<span className="tj-cur">USDT</span>}
              value={currrencyUSDT?.value || ""}
              onChange={(ev) =>
                setCurrencyUSDT({
                  key: "ten",
                  title: "USDT",
                  value: ev.target.value,
                })
              }
            />
          </Form.Item>

          <Button
            type="primary"
            loading={loading}
            onClick={() => onSendData()}
            size="large"
            block
            // disabled={!currrencies[0]?.value || !currrencies[1]?.value}
            className="currency-from-btn"
          >
            Сохранить
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default CurrenciesPage;
